import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: 'login'
  },
  {
    name:'login',
    component: () => import('../view/login/login'),
    meta: {
      title: '登录'
    }
  },
  {
    name:'bindAccount',
    component: () => import('../view/login/bindAccount'),
    meta: {
      title: '登录'
    }
  },
  {
    name:'approveList',
    component: () => import('../view/approve/approveList'),
    meta: {
      title: '审批列表'
    }
  },
  {
    name:'approveDetails',
    component: () => import('../view/approve/approveDetails'),
    meta: {
      title: '审批详情'
    }
  },
  {
    name:'clueApproveDetail',
    component: () => import('../view/approve/clueApproveDetail'),
    meta: {
      title: '审批详情'
    }
  },
  {
    name:'clueMoveApproveDetail',
    component: () => import('../view/approve/clueMoveApproveDetail'),
    meta: {
      title: '审批详情'
    }
  },
  {
    name:'znPartTimePositionApproveDetails',
    component: () => import('../view/approve/znPartTimePositionApproveDetail'),
    meta: {
      title: '审批详情'
    }
  }
];
// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

router.afterEach((to,from) => {
  window.scrollTo(0,0);
})

export {
  router
};
