<template>
  <div class="empty-frame">
    <div class="empty">
      <div class="empty-photo"></div>
      <div class="empty-text">啊哦，这里好像什么都没有</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonEmpty",
  data() {
    return {
      curHeight: '',
    }
  },
}

</script>

<style scoped>
.van-tabs__content {
  background-color: #f8f8f8;
}

.empty-frame {
  width: 100%;
  background-color: #f8f8f8;
  height: 10rem;
}

.empty {
  width: 40%;
  padding-top: 3rem;
  margin: 0 auto;

}

.empty-photo {
  width: 90px;
  height: 71px;
  background-image: url("../../../assets/images/empty.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 25px;
}

.empty-text {
  color: #ACACAC;
  font-size: 12px;
  margin-top: 20px;
}
</style>