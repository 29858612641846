<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<style>
body {
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

</style>
<script>
  export default {
    created: function (){
      // ios页面输入框失焦要页面重置到顶部
      if (this.isIos()) { // 判断是否是ios端
        let flag = false
        let pageBackNormFunc
        let xnum = 0
        let ynum = 0
        document.body.addEventListener('focusin', () => {
          // 软键盘弹起事件
          flag = true
          ynum = window.pageYOffset;
          pageBackNormFunc && clearTimeout(pageBackNormFunc)
        })
        document.body.addEventListener('focusout', () => {
          // 软键盘关闭事件
          if (flag) {
            pageBackNormFunc = setTimeout(function () { // // 当键盘收起的时候让页面回到原始位置
              window.scrollTo( xnum, ynum)
            }, 200)
          }
          flag = false
        })
      }
    },
    methods: {
      isIos:function () {
        const u = navigator.userAgent
        return !!u.match(/\(i[^]+( U)? CPU.+Mac OS X/)
      }
    }
  }
</script>
