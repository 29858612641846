import Vue from 'vue';
import App from './App';
import 'amfe-flexible';
import { router } from './router/router';

import commonEmpty from '@/components/bright/bh-empty/index';
Vue.use(commonEmpty)

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
